import { message } from "antd"; // Importing message component from Ant Design for displaying notifications
import axios from "axios"; // Importing axios for making HTTP requests

/**
 * BASE_DOMAIN - Determines the base domain based on the current hostname.
 * If the hostname is 'portal-uat.kistpay.com', it sets the domain to 'https://api-uat.kistpay.com'.
 * If the hostname is 'kistpay-portal.kistpay.com', it sets the domain to 'https://api.kistpay.com'.
 * For any other hostname, it defaults to 'https://api.kistpay.com'.
 */

export const BASE_DOMAIN = (() => {
  if (window.location.hostname === "portal-uat.kistpay.com") {
    return "https://api-uat.kistpay.com";
  }
  if (window.location.hostname === "uat-jazz-portal.kistpay.com") {
    return "https://api-uat.kistpay.com";
  }
  if (window.location.hostname === "kistpay-portal-v20-qa-144279972459.us-central1.run.app" || window.location.hostname === "kistpay-portal-v20-qa-ihtuwbv5bq-uc.a.run.app") {
    return "https://api-qa.kistpay.com";
  }
  if (window.location.hostname === "qa-jazz-portal.kistpay.com") {
    return "https://api-qa.kistpay.com";
  }
  if (window.location.hostname === "sandbox-portal.kistpay.com") {
    return "https://api-sandbox.kistpay.com";
  }
  if (window.location.hostname === "jazz-sandbox-portal.kistpay.com") {
    return "https://api-sandbox.kistpay.com";
  }
  if (window.location.hostname === "kistpay-portal.kistpay.com") {
    return "https://api.kistpay.com";
  }
  if (window.location.hostname === "jazz-portal.kistpay.com") {
    return "https://api.kistpay.com";
  } else {
    return "https://api-dev.kistpay.com";
  }
})();

export const BASE_URL = BASE_DOMAIN; // Set BASE_URL to the determined BASE_DOMAIN

// Create an axios instance with baseURL and timeout settings
const API = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  httpAgent: true,
});

/**
 * Interceptor for requests - adds Authorization header if necessary.
 * It retrieves tokens from localStorage and sets the Authorization header based on the conditions:
 * 1. If B2B token condition is true, use a specific B2B token.
 * 2. Otherwise, use the Auth token or a default token based on the environment.
 */

API.interceptors.request.use(
  (config) => {
    // Retrieve tokens from localStorage
    const Auth_token = localStorage.getItem("auth_token"); // Retrieve Auth token from localStorage
    const B2b_token = localStorage.getItem("isB2B"); // Retrieve B2B flag from localStorage
    const B2B_TOKEN = localStorage.getItem("B2B_TOKEN"); // Retrieve B2B token from localStorage
    const isUFONE = localStorage.getItem("isUfone"); // Retrieve B2B token from localStorage

    // Decide which token to use
    // If B2B token condition is true, use a specific B2B token
    // Otherwise, use the Auth token or a default one
    let token;
    if (B2b_token === "true") {
      // Assuming you have a specific B2B token to use when B2b_token is true
      // Replace 'YOUR_B2B_TOKEN_HERE' with how you access/store your B2B token
      token = B2B_TOKEN;
    } else {
      // Determine the default token based on the environment
      let BASETOKEN = ""; // Default to UAT token
      if (BASE_DOMAIN === "https://api.kistpay.com") {
        BASETOKEN = isUFONE
          ? process.env.REACT_APP_PROD_UFONE_TOKEN
          : process.env.REACT_APP_PROD_TOKEN; // Production token
      } else if (BASE_DOMAIN === "https://api.kistpay.com") {
        BASETOKEN = process.env.REACT_APP_PROD_TOKEN; // UAT token
      } else if (BASE_DOMAIN === "https://api-qa.kistpay.com") {
        BASETOKEN = isUFONE
          ? process.env.REACT_APP_QA_UFONE_TOKEN
          : process.env.REACT_APP_QA_TOKEN; // UAT token
      } else if (BASE_DOMAIN === "https://api-uat.kistpay.com") {
        BASETOKEN = isUFONE
          ? process.env.REACT_APP_UAT_UFONE_TOKEN
          : process.env.REACT_APP_UAT_TOKEN; // UAT token
      } else if (BASE_DOMAIN === "https://api-qa.kistpay.com") {
        BASETOKEN = process.env.REACT_APP_QA_TOKEN; // UAT token
      } else if (BASE_DOMAIN === "https://api-uat.kistpay.com") {
        BASETOKEN = process.env.REACT_APP_UAT_TOKEN; // UAT token
      } else if (BASE_DOMAIN === "https://api-sandbox.kistpay.com") {
        BASETOKEN = process.env.REACT_APP_SANDBOX_TOKEN; // UAT token
      } else {
        BASETOKEN = isUFONE
          ? process.env.REACT_APP_DEV_UFONE_TOKEN
          : process.env.REACT_APP_DEV_TOKEN; // Default token
      }

      // Use the Auth token or a default token
      token = Auth_token === null ? BASETOKEN : Auth_token;
    }

    // Exclude the login URL from requiring the token
    if (!config.url.includes("/authentication/v1/auth/login")) {
      config.headers["Authorization"] = `Bearer ${token}`; // Set the Authorization header
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

/**
 * Interceptor for responses - handles response data and errors.
 * If the status code is within 2xx range, it returns the response data.
 * If the status code is outside 2xx range, it handles specific errors (e.g., 401, 503).
 */

API.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    // You can dispatch actions here if needed
    // store.dispatch(someAction());

    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // Example: Handle 403 Forbidden error
    if (error.response) {
      // Handle session expiration, redirect to login, show a message, etc.
      // store.dispatch(sessionExpiredAction());
      console.log(error.response);
      if (error.response.status === 503) {
        // store.dispatch(setError(true))
        message.error(
          "Service is temporarily unavailable. Please try again later."
        );
      }
      if (error.response.status === 401) {
        // store.dispatch(setError(true))
        message.error("Session Expired");
        localStorage.clear();
        window.location.reload();
      }
    }

    return Promise.reject(error);
  }
);

export default API;
